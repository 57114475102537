@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;600;700;800;900&display=swap');

/* common colors */
:root {
  --gs-blue: #3366CC;
  --gs-bluegreen: #009999;
  --gs-green: #99CC00;
  --gs-orange: #CC6600;
  --gs-gray: #EBEBE3;
  --gs-darkgray: #969696;
  --primary-black: #000;
  --primary-white: #FFF;
}

/* common font */
body {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  color: var(--primary-black);
  background-color: var(--primary-white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
fieldset,
blockquote,
address,
table,
thead,
tbody,
tfoot,
tr,
td,
caption {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

/* layout */
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-black);
}

.wrap {
  width: 100%;
  height: 100%;
  background-color: var(--gs-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrap > * {
  width: calc(100% - 25px);
  text-align: center;
  padding: 10px;
}
.wrap .text-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.wrap .text-logo h1{
  font-size: 0;
  margin-right: 25px;
}
.wrap .text-logo img{
  width: 60px;
  height: 60px;
}
.wrap .text-logo h2{
  font-size: 1em;
}
.wrap .text-logo h2 span{
  color: var(--gs-orange)
}
.wrap strong {
  font-size: 2em;
  margin:110px 0;
}
.wrap p {
  font-size: 1.3em;
  color: var(--gs-blue);
  font-weight: 700;
  height: 600px;
}

/*Clock*/
.clock span {
  font-size: 0.6em;
  font-weight: 500;
  color: var(--gs-darkgray);
}

/*Video for GS*/
.wrap .video-area {
  height:80%;
}

/*Responsive in Horizontal mode*/
@media screen and (min-width: 1280px){
  .wrap .text-logo {
    margin-top: 0;
  }
  .wrap .text-logo h1{
    margin-right: 20px;

  }
  .wrap .text-logo h2{
    font-size: 0.9em;
  }
  .wrap strong {
    font-size: 1.4em;
    margin: 0;
  }
  .wrap p {
    font-size: 1em;
    height: 500px;
  }
  .clock span {
    text-align: right;
    display: block;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}


